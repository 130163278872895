@tailwind base;
@tailwind components;
@tailwind utilities;

/* .App {
  text-align: center;
}
*/
.App {
  text-align: center;
}

.videocontainer { 
    position: relative; 
    height: 100vh; 
    width: auto; 
    overflow: hidden; 
 }
 
video{
  @apply w-auto max-w-none !important;
 }

.App-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



